import React, { useEffect, useState } from 'react';
import HomeLayout from '../asset/layout/HomeLayout';
import { ProfileCard } from '../components/Cards/ProfileCard';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/material';

import { MasterCard } from '../components/Cards/MasterCard';
import { getAllUsers } from '../actions/userAuth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import DatePickerComponent from '../components/DatePickerComponent';

const dummyData = [
  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },
  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },
  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },

  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },
  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },
  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },
  {
    name: 'Johnny Ahmad',
    image: '/Ellipse.svg',
    dis: 'Lorem Ipsum',
  },
];

const iconData = [
  {
    src: '/UsersGroupRounded.svg',
    alt: 'Total Users',
    name: 'Total Users',
    bgColor: '#FFF5D9',
  },
  {
    src: '/UserMinusRounded.svg',
    alt: 'Old',
    name: 'New Users',
    bgColor: '#DCFAF8',
  },
  {
    src: '/UserPlusRounded.svg',
    alt: 'New',
    name: 'Users This Week',
    bgColor: '#E7EDFF',
  },
  {
    src: '/UserCheckRounded.svg',
    alt: 'Exiting',
    name: 'Users This Month',
    bgColor: '#FFE0EB',
  },
];

const UserProfile = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [weekUsers, setWeekUsers] = useState(0);
  const [monthUsers, setMonthUsers] = useState(0);
  const [yearlyUsers, setYearlyUsers] = useState(0);

  const dispatch = useDispatch();

  const Navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      Navigate('/');
    }
  }, [Navigate]);

  const currentDate = new Date();

  // Function to check if a user was created within the current week
  const isUserCreatedThisWeek = (user) => {
    const userCreatedAt = new Date(user.createdAt);
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Start of current week (Sunday)
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // End of current week (Saturday)
    return userCreatedAt >= startOfWeek && userCreatedAt <= endOfWeek;
  };

  // Function to check if a user was created within the current month
  const isUserCreatedThisMonth = (user) => {
    const userCreatedAt = new Date(user.createdAt);
    return (
      userCreatedAt.getMonth() === currentDate.getMonth() &&
      userCreatedAt.getFullYear() === currentDate.getFullYear()
    );
  };

  // Function to check if a user was created within the current month
  const isUserCreatedThisMonth1 = (user) => {
    const userCreatedAt = new Date(user.createdAt);
    return (
      userCreatedAt.getMonth() - 1 === currentDate.getMonth() - 1 &&
      userCreatedAt.getFullYear() === currentDate.getFullYear()
    );
  };

  // Function to check if a user was created within the current year
  const isUserCreatedThisYear = (user) => {
    const userCreatedAt = new Date(user.createdAt);
    return userCreatedAt === currentDate;
  };

  useEffect(() => {
    dispatch(
      getAllUsers(
        (data) => {
          console.log(data.data.data.users);

          // Count users created this week
          const usersThisWeek = data.data.data.users.filter(
            isUserCreatedThisWeek
          ).length;

          // Count users created this month
          const usersThisMonth = data.data.data.users.filter(
            isUserCreatedThisMonth
          ).length;

          // Count users created this year
          const usersThisYear = data.data.data.users.filter(
            isUserCreatedThisYear
          ).length;
          setTotalUsers(data.data.data.users.length);
          setWeekUsers(usersThisWeek);
          setMonthUsers(usersThisMonth);
          setYearlyUsers(usersThisYear);

          setUsers(data.data.data.users);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, []);
  return (
    <Box sx={{ display: 'flex', background: '#F5F7FA' }}>
      <HomeLayout>
        <Container>
          <Grid container spacing={2} my={4}>
            {iconData?.map((data, index) => (
              <Grid
                item
                key={index}
                md={4}
                xs={6}
                xl={3}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <MasterCard
                  {...data}
                  totalUsers={totalUsers}
                  weekUsers={weekUsers}
                  monthUsers={monthUsers}
                  yearlyUsers={yearlyUsers}
                />
              </Grid>
            ))}
          </Grid>

          <Typography mt={5} mb={2} variant='h3'>
            Onboarded Users
          </Typography>
          <Grid container spacing={3}>
            {users?.map((data, index) => (
              <Grid item key={index} md={3} xs={6} xl={2.4} sm={4}>
                <ProfileCard
                  {...data}
                  showMessageButton={false}
                  image={data}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </HomeLayout>
    </Box>
  );
};
export default UserProfile;
