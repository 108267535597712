import React, { useEffect, useState } from 'react';
import HomeLayout from '../../asset/layout/HomeLayout';
import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Snackbar,
  IconButton,
  TableBody,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import InputField1 from '../../components/mui/InputField1';
import CustomButton from '../../components/mui/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import BankDetailsModal from './BankDetailsModal';
import { useDispatch } from 'react-redux';
import {
  addRecharge,
  RechargeRequestById,
  uploadDocument,
} from '../../actions/userAuth';
import { useSnackbar } from 'notistack';
import DropDownMenu from '../../components/mui/DropDownMenu';
import RewardHistoryModal from './RewardHistoryModal';
import dayjs from 'dayjs';

const columns = [
  'Transaction Receipt',
  'Amount',
  'Bank Account Number',
  'Transaction Date',
  'Transaction Id',
  'Transaction Mode',
  'Created On',
  'Status',
];

const RechargeWallet = () => {
  const [formData, setFormData] = useState({
    bankAccountNumber: '',
    requestAmount: '',
    paymentMode: '',
    transactionDate: '',
    transactionNumber: '',
    comment: '',
    addressProofDocument: null,
  });

  const [fileUrl, setFileUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rechargeData, setRechargeData] = useState([]);
  const [openRewardHistoryModal, setOpenRewardHistoryModal] =
    useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const companyId = localStorage.getItem('siriPayUserID');
  console.log('company id is', companyId);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      addressProofDocument: file,
    });

    handleFileUpload(file);
  };

  const getData = () => {
    setLoading(true);
    dispatch(
      RechargeRequestById(
        companyId,
        (data) => {
          setRechargeData(data.data.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [companyId, dispatch]);

  const handleFileUpload = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(
        uploadDocument(
          formData,
          (response) => {
            setFileUrl(response.data);
            enqueueSnackbar('File uploaded successfully.', {
              variant: 'success',
            });
          },
          (error) => {
            enqueueSnackbar(error.message, {
              variant: 'error',
            });
          }
        )
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const dataToSubmit = {
      bankAccountNumber: formData.bankAccountNumber,
      amount: parseFloat(formData.requestAmount),
      transactionMode: formData.paymentMode,
      transactionDate: formData.transactionDate,
      transactionId: formData.transactionNumber,
      comment: formData.comment,
      transactionReceipt: fileUrl,
      remark: 'remark',
    };

    dispatch(
      addRecharge(
        dataToSubmit,
        (response) => {
          enqueueSnackbar('Data submitted successfully', {
            variant: 'success',
          });
          setLoading(false);

          handleCloseModal();
          resetForm();
          getData();
        },
        (error) => {
          enqueueSnackbar('Error submitting data', {
            variant: 'error',
          });
          setLoading(false);
        }
      )
    );
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const resetForm = () => {
    setFormData({
      bankAccountNumber: '',
      requestAmount: '',
      paymentMode: '',
      transactionDate: '',
      transactionNumber: '',
      comment: '',
      addressProofDocument: null,
    });
    setFileUrl('');
  };

  const handleOpenRewardHistoryModal = () => {
    setOpenRewardHistoryModal(true);
  };

  const handleCloseRewardHistoryModal = () => {
    setOpenRewardHistoryModal(false);
  };

  return (
    <HomeLayout>
      <Box
        sx={{
          background: '#F5F7FA',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: '4%',
        }}
      >
        <Container maxWidth='xl'>
          <Grid container justifyContent={'space-between'}>
            <Grid item spacing={6} pb={4}>
              <Typography variant='h4'>
                Recharge a Wallet
              </Typography>
            </Grid>
            <Grid item>
              <Grid item spacing={6} pb={4}>
                <CustomButton
                  variant='contained'
                  width='auto'
                  color='primary'
                  onClick={handleOpenModal}
                >
                  View Bank Details
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>

          <Paper elevation={3} sx={{ px: 4, py: 1 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={0.5}>
                <Grid item md={6} xs={12}>
                  <InputField1
                    placeholderColor='#8D98AA'
                    label='Bank Account Number'
                    placeholder='Enter Bank Account Number'
                    spanP={true}
                    required
                    maxLength={20}
                    name='bankAccountNumber'
                    value={formData.bankAccountNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField1
                    placeholderColor='#8D98AA'
                    label='Request Amount'
                    placeholder='Enter Request Amount'
                    spanP={true}
                    required
                    maxLength={20}
                    name='requestAmount'
                    value={formData.requestAmount}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant='h6' pt={2} pb={1}>
                    Payment Mode
                  </Typography>
                  <DropDownMenu
                    buttonText={
                      formData.paymentMode ||
                      'Select Payment Mode'
                    }
                    menuItems={[
                      { label: 'Imps/Rtgs/NEFT' },
                      { label: 'UPI' },
                      { label: 'Virtual Account' },
                      { label: 'OffLine Mode' },
                    ]}
                    selectedItem={formData.paymentMode}
                    setSelectedItem={(item) =>
                      setFormData({
                        ...formData,
                        paymentMode: item.label,
                      })
                    }
                    buttonWidth='100%'
                    name='paymentMode'
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField1
                    placeholderColor='#8D98AA'
                    label='Transaction Date'
                    spanP={true}
                    name='transactionDate'
                    value={formData.transactionDate}
                    onChange={handleChange}
                    type={'date'}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField1
                    placeholderColor='#8D98AA'
                    label='Transaction Number'
                    placeholder='Enter Transaction Number'
                    spanP={true}
                    required
                    maxLength={20}
                    name='transactionNumber'
                    value={formData.transactionNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField1
                    label='Upload Address Proof Document'
                    fileUpload={true}
                    onChange={handleFileChange}
                    py={'10px'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' my={1}>
                    Comment
                  </Typography>
                  <TextField
                    multiline
                    fullWidth
                    placeholder='Enter comment here ......'
                    name='comment'
                    value={formData.comment}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Box mt={3} textAlign='right'>
                <CustomButton
                  variant='contained'
                  width='10rem'
                  color='primary'
                  type='submit'
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Recharge Wallet'}
                </CustomButton>
              </Box>
              <BankDetailsModal
                open={openModal}
                onClose={handleCloseModal}
                formData={formData}
              />
              <RewardHistoryModal
                open={openRewardHistoryModal}
                onClose={handleCloseRewardHistoryModal}
                formData={formData}
              />
            </form>
          </Paper>
          {loading ? (
            <Box align='center' my={5}>
              <CircularProgress size={24} />
            </Box>
          ) : rechargeData.length > 0 ? (
            <TableContainer
              component={Paper}
              sx={{ borderRadius: '25px', my: 3 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#11409B',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          textAlign: 'center',
                        }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    whiteSpace: 'nowrap',
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  {rechargeData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <embed
                          src={row?.transactionReceipt}
                          width={'100%'}
                          height={'100'}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.amount}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.bankAccountNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.transactionDate
                          ? dayjs(row?.transactionDate).format(
                              'MM-DD-YYYY'
                            )
                          : ''}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.transactionId}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.transactionMode}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.createdAt
                          ? dayjs(row.createdAt).format(
                              'MM-DD-YYYY'
                            )
                          : ''}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        {row?.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              variant='body1'
              sx={{ textAlign: 'center', my: 3 }}
            >
              No results found.
            </Typography>
          )}
        </Container>
      </Box>
    </HomeLayout>
  );
};

export default RechargeWallet;
