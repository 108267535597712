import React from 'react';
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import CustomButton from '../../components/mui/CustomButton';

const BankDetailsModal = ({ open, onClose, formData }) => {
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { md: '40%', xs: '90%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id='transition-modal-title'
            variant='h3'
            component='h2'
          >
            Via Fund Transfer
          </Typography>
          <Typography
            id='transition-modal-description'
            sx={{ mt: 4 }}
          >
            <Typography variant='h6' component={'span'}>
              Account Holder Name:{' '}
            </Typography>
            {`   budgetree technology pvt ltd`}
          </Typography>
          <Typography
            id='transition-modal-description'
            sx={{ mt: 2 }}
          >
            {' '}
            <Typography variant='h6' component={'span'}>
              Bank Name:
            </Typography>
            {`    IDFC First Bank`}
          </Typography>
          <Typography
            id='transition-modal-description'
            sx={{ mt: 2 }}
          >
            {' '}
            <Typography variant='h6' component={'span'}>
              Account Number:
            </Typography>
            {`        10067627509`}
          </Typography>
          <Typography
            id='transition-modal-description'
            sx={{ mt: 2 }}
          >
            {' '}
            <Typography variant='h6' component={'span'}>
              IFSC Code:{' '}
            </Typography>
            {`        IDFB0020224`}
          </Typography>
          <Box mt={2} textAlign='right'>
            <CustomButton
              variant='contained'
              color='primary'
              onClick={onClose}
              width={'10rem'}
            >
              Close
            </CustomButton>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BankDetailsModal;
