import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./globalStyles.css";
import Theme from "./asset/Theme/Theme";

import { Login } from "./pages/User/Login";
import SignUp from "./pages/User/SignUp";
import ForgotPassword from "./pages/User/ForgotPassword";
import ResetPassword from "./pages/User/ResetPassword";
import { DashBoard } from "./pages/dashBoard/DashBoard";
import UserProfile from "./pages/UserProfile";

import EditProfile from "./pages/editprofile/EditProfile";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserByToken } from "./actions/userAuth";
import AddGst from "./pages/AddGst/AddGst";

import CompanyEmployee from "./pages/CompanyEmployee/CompanyEmployee";
import EditCompanyInfo from "./pages/companyInfo/EditCompanyInfo";
import RewardsPoint from "./pages/Rewards/RewardsPoint";
import RechargeWallet from "./pages/rechargeWallet/RechargeWallet";
import Merchandise from "./pages/Merchandise";
import ProductPage from "./pages/Merchandise/ProductPage/ProductPage";
import CartPage from "./pages/Merchandise/cart/CartPage";
import { SnackbarProvider } from "notistack";

export default function App() {
  const userToken = localStorage.getItem("token");

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Theme>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/user" element={<UserProfile />} />

            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/customer/company/add-gst" element={<AddGst />} />
            <Route path="/company/employee" element={<CompanyEmployee />} />
            <Route path="/rewards/rewards-point" element={<RewardsPoint />} />
            <Route path="/Merchandise/Merchandise" element={<Merchandise />} />
            <Route path="/Merchandise/Product-page" element={<ProductPage />} />
            <Route path="/Merchandise/cart" element={<CartPage />} />
            <Route path="/edit-company-info" element={<EditCompanyInfo />} />
            <Route path="/recharge-wallet" element={<RechargeWallet />} />
          </Routes>
        </BrowserRouter>
      </Theme>
    </SnackbarProvider>
  );
}
