import React, { useEffect, useState } from "react";
import Section from "../../components/Section";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import InputField1 from "../../components/mui/InputField1";
import CustomButton from "../../components/mui/CustomButton";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { sendOtp, signUp, verifyOtp } from "../../actions/userAuth";
import { enqueueSnackbar } from "notistack";

const SignUp = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [otpInputActive, setOtpInputActive] = useState(false);
  const [sendOtpLoading, setSendOtpLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      validateEmail(email);
      validatePhone(phone);
    }, 300); // Adjust debounce delay as needed

    return () => clearTimeout(debounceTimer);
  }, [email, phone]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const validateEmail = (email) => {
    if (email.trim() === "") {
      setIsEmailValid(true);
    } else {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const isValidEmail = emailRegex.test(email);
      setIsEmailValid(isValidEmail);
    }
  };

  const validatePhone = (phone) => {
    const isValidPhone = phone.trim().length === 10;
    setIsPhoneValid(isValidPhone);
  };

  const validateForm = () => {
    const isValidEmail = email.trim() !== "" && isEmailValid;
    const isValidPhone = phone.trim() !== "" && isPhoneValid;
    const isValidName = firstname.trim() !== "";
    const isValidlastName = lastname.trim() !== "";
    return isValidEmail && isValidPhone && isValidName;
  };

  const dispatch = useDispatch();

  const handleSignUp = () => {
    if (!validateForm()) {
      console.log("Please fill all the fields correctly.");
      return;
    }

    const payload = {
      firstName: firstname,
      lastName: lastname,
      email: email,
      phone: phone,
    };
    // console.log("payload,", payload);

    setSendOtpLoading(true);

    dispatch(
      signUp(
        payload,
        (response) => {
          console.log("OTP sent:", response);
          setOtpInputActive(true);
          setSendOtpLoading(false);
          localStorage.setItem("token", response.data.token);
          enqueueSnackbar("OTP sent successfully!", { variant: "success" });
        },
        (error) => {
          console.log("Error sending OTP:", error);
          setSendOtpLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            enqueueSnackbar(error.response.data.message, { variant: "error" });
          } else {
            enqueueSnackbar("An error occurred. Please try again.", {
              variant: "error",
            });
          }
        }
      )
    );
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log("Please fill all the fields correctly.");
      return;
    }

    const payload = {
      otp: otp,
      token: localStorage.getItem("token"),
    };

    dispatch(
      verifyOtp(
        payload,
        (response) => {
          console.log("OTP sent:", response);
          setOtpInputActive(true);
          setSendOtpLoading(false);
          setTimeout(() => {
            setLoginLoading(false);
            navigate("/customer/company/add-gst");
          }, 2000);
        },
        (error) => {
          console.log("Error verifying OTP:", error);

          setSendOtpLoading(false);
          setErrorMessage("");
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
          });
          if (error.response.data.message) {
            const { message } = error.response.data.message;
            setErrorMessage(message);
          } else {
            setErrorMessage("An unexpected error occurred. Please try again.");
          }
        }
      )
    );
  };

  const otpStyle = {
    input: {
      minHeight: 40,
      fontSize: 16,
      flexGrow: 1,
      "&+input": { ml: 2 },

      appearance: "textfield",
    },
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
      display: "none",
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
      display: "none",
    },
  };
  const inputStyle = {
    ...otpStyle.input,
    width: "15%",
    height: "4rem",
    margin: "1%",
    borderRadius: "8px",
    border: "1px solid gray",
    background: "#fff",
    textAlign: "center",
    "&:hover, &:active, &:focus": {
      "& .otp-input-arrow": {
        display: "none",
        border: "1px solid gray",
      },
    },
  };

  return (
    <Section>
      <Container maxWidth={"xl"}>
        <Box mt={4}>
          <img
            src="/logo.webp"
            style={{
              height: {
                xl: "41.01px",
                md: "41.01px",
                xs: "20px",
              },
              width: "199px",
            }}
            alt="logo"
          />
        </Box>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ minHeight: "calc(100vh - 100px)" }}
        >
          <Grid item md={5} xs={12}>
            <Box my={2} sx={{ mx: { xs: 0, sm: 0, md: 6, lg: 6, xl: 6 } }}>
              <Typography variant="h1" component="h2">
                Sign Up
              </Typography>
              <Typography
                variant="h5"
                component="p"
                mt={1}
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              >
                Please fill out your details to create your account.
              </Typography>
              <Box maxWidth={600} mx={"auto"} mt={3}>
                <form onSubmit={handleVerifyOtp}>
                  <Stack spacing={3}>
                    <InputField1
                      label="First Name"
                      placeholder="Your First Name"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    <InputField1
                      label="Last Name"
                      placeholder="Your Last Name"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <InputField1
                      label="Phone Number"
                      placeholder="Your Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      maxLength={10}
                      type="Number"
                    />
                    <InputField1
                      label="Email"
                      placeholder="Your Email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                    {!isEmailValid && (
                      <Typography sx={{ color: "#d32f2f" }}>
                        Please enter a valid Email ID
                      </Typography>
                    )}
                    {otpInputActive ? (
                      <>
                        <Typography sx={{ textAlign: "center" }}>
                          Enter OTP sent to your email and phone
                        </Typography>
                        <OTPInput
                          value={otp}
                          onChange={(otp) => setOtp(otp)}
                          numInputs={5}
                          isInputNumValid={() => true}
                          separator={<span> </span>}
                          renderInput={(props) => (
                            <input
                              {...props}
                              style={inputStyle}
                              disabled={!otpInputActive}
                            />
                          )}
                        />{" "}
                        {errorMessage && (
                          <Typography sx={{ color: "red" }}>
                            {errorMessage}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <CustomButton
                        onClick={handleSignUp}
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={!validateForm() || sendOtpLoading}
                      >
                        {sendOtpLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Send OTP"
                        )}
                      </CustomButton>
                    )}
                    {otpInputActive && (
                      <CustomButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={otp.length !== 5 || loginLoading}
                      >
                        {loginLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Submit"
                        )}
                      </CustomButton>
                    )}
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        color: "#757575",
                      }}
                    >
                      Already have an account?{" "}
                      <Link
                        to="/"
                        style={{
                          color: "#3f51b5",
                          textDecoration: "none",
                        }}
                      >
                        Sign in
                      </Link>
                    </Typography>
                  </Stack>
                </form>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} sx={{ display: { md: "block", xs: "none" } }}>
            <Box
              sx={{
                px: 5,

                minHeight: "calc(100vh - 200px)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src="/signUp.svg"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "3px",
                }}
                alt="Sign Up Illustration"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default SignUp;
