// DropDownMenu.js

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: '#fff', // White background
    boxShadow:
      'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function DropDownMenu({
  buttonText,
  menuItems,
  buttonWidth,
  selectedItem,
  setSelectedItem,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
    handleClose();
  };

  return (
    <div>
      <Button
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='contained'
        disableElevation
        onClick={handleClick}
        sx={{
          backgroundColor: 'white',
          border: '1px solid #DFDFDF',
          color: 'gray',
          fontSize: '13px',
          fontWeight: '500',
          textTransform: 'none',
          height: '2.5rem',
          width: buttonWidth || {
            xl: '200px',
            lg: '150px',
            md: '16rem',
            sm: '16rem',
            xs: '16.4rem',
          },
          '&:hover': {
            backgroundColor: 'white',
            border: '1px solid #11409B',
            color: 'gray',
          },
          '&:active': {
            backgroundColor: 'white',
            border: '1px solid #11409B',
            color: 'gray',
          },
        }}
      >
        {selectedItem ? selectedItem : buttonText}
        <KeyboardArrowDownIcon sx={{ marginLeft: 'auto' }} />
      </Button>
      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {Array.isArray(menuItems) &&
          menuItems.map((item, index) => (
            <div key={index}>
              <MenuItem
                onClick={() => handleMenuItemClick(item)}
                disableRipple
              >
                {item.label}
              </MenuItem>
              {index < menuItems.length - 1 && <Divider />}
            </div>
          ))}
      </StyledMenu>
    </div>
  );
}
