import React from 'react';
import { Box, Typography } from '@mui/material';
import { primaryColor } from '../../style/color';

const InputField1 = React.forwardRef(
  (
    {
      required = false,
      label,
      placeholder,
      value,
      onChange,
      error,
      helperText,
      endIcon,
      marginRight,
      height,
      disabled = false,
      type,
      maxLength,
      placeholderColor,
      spanP = false,
      max,
      fileUpload = false,
      py,
      name,
      accept, // Ensure name prop is forwarded
    },
    ref
  ) => {
    return (
      <Box
        sx={{
          position: 'relative',
          marginRight: marginRight || '',
          label: { display: 'block' },
          width: '100%',
          '& .inputContainer': {
            position: 'relative',
          },
          '& .input': {
            height: height || 44,
            borderRadius: 2,
            fontSize: 15,
            px: 2,
            width: '100%',
            maxWidth: '100%',
            paddingRight: endIcon ? '30px' : '2px',
            border: `1px solid ${
              error ? primaryColor.main : '#D0D5DD'
            }`,
            transition: '0.3s',
            boxShadow: '0px 0px 25px rgb(0,0,0,0.02)',
            fontFamily: 'Inter, sans-serif',
            bgcolor: 'none',
            '&:hover': { borderColor: 'primary.main' },
            '&:focus': {
              outline: 'none',
              border: `1px solid ${primaryColor.light}`,
            },
            '&:disabled': {
              backgroundColor: '#f2f2f2',
              color: 'rgba(0, 0, 0, 0.38)',
              cursor: 'not-allowed',
            },
            '::placeholder': {
              color: placeholderColor || 'inherit',
              opacity: 0.5,
            },
          },
          '& .fileInput': {
            height: height || 44,
            width: '100%',
            maxWidth: '100%',
            fontSize: 15,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            py: py || '3.2%',
            px: 1,
            border: `1px solid ${
              error ? primaryColor.main : '#D0D5DD'
            }`,
            transition: '0.3s',
            boxShadow: '0px 0px 25px rgb(0,0,0,0.02)',
            bgcolor: 'none',
            '&:hover': { borderColor: 'primary.main' },
            '&:focus': {
              outline: 'none',
              border: `1px solid ${primaryColor.light}`,
            },
            '&:disabled': {
              backgroundColor: '#f2f2f2',
              color: 'rgba(0, 0, 0, 0.38)',
              cursor: 'not-allowed',
            },
          },
          '& .endIconContainer': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '10px',
          },
        }}
      >
        {label && (
          <Typography variant='h6' component={'label'} my={1.5}>
            {label}
            {spanP ? (
              <span style={{ color: 'red' }}>*</span>
            ) : null}
          </Typography>
        )}
        <Box
          className='inputContainer'
          sx={{ position: 'relative' }}
        >
          {fileUpload ? (
            <input
              className='fileInput'
              type='file'
              ref={ref}
              onChange={onChange}
              style={{ height: height || 44 }} // Adjust height here as needed
            />
          ) : (
            <input
              className='input'
              placeholder={placeholder || ''}
              required={required}
              value={value}
              onChange={onChange}
              disabled={disabled}
              type={type === type ? type : 'text'}
              max={max}
              maxLength={maxLength}
              name={name}
              accept={accept}
            />
          )}
          <Box
            className='endIconContainer'
            sx={{
              position: 'absolute',
              top: '79%',
              transform: 'translateY(-50%)',
              right: '10px',
            }}
          >
            {endIcon && <>{endIcon}</>}
          </Box>
        </Box>
        {helperText && (
          <Typography
            variant='subtitle2'
            mt={0.3}
            color={error ? primaryColor.main : '#667085'}
          >
            {helperText}
          </Typography>
        )}
      </Box>
    );
  }
);

export default InputField1;
