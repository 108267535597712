import React from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Box,
  Stack,
} from "@mui/material";
import HomeLayout from "../../../asset/layout/HomeLayout";

export default function ProductPage() {
  return (
    <HomeLayout>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Stack spacing={4}>
              <Typography variant="h4" component="h1">
                Voyager Paris Theo Laptop Bag-Blue
              </Typography>
              <Typography variant="h3" component="p">
                INR 2,100
              </Typography>
              <Typography variant="h4" component="h2" fontWeight={600}>
                Features
              </Typography>
              <Typography variant="p" component="p">
                The Theo Laptop Bag by Voyager Paris is an embodiment of
                philosophical harmony, merging materials and design in a
                captivating symphony. Its canvas, embracing three-fourths of its
                form, symbolizes the raw essence of authenticity and
                roundedness, while the PU leather, adorning one-fourth,
                represents the transformative power of adaptation and growth.
                The four buckle straps, two on each side, serve as reminders of
                the interconnectedness between strength and restraint, guiding
                us to find balance in the face of life’s challenges.
              </Typography>
              <Typography variant="h6" component="h2">
                Details & Frequently Asked Questions
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Card>
              <CardMedia
                component="img"
                image="/path/to/your/image.jpg" // Replace with your image path
                alt="Voyager Paris Theo Laptop Bag"
              />
            </Card>
            <Grid container spacing={2} sx={{ marginTop: 2 }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ textTransform: "none", fontWeight: 500 }}
                >
                  Buy Now
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{ textTransform: "none", fontWeight: 500 }}
                >
                  Add To Cart
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </HomeLayout>
  );
}
