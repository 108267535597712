import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  TextField,
  Stack,
  Divider,
} from "@mui/material";
import HomeLayout from "../../../asset/layout/HomeLayout";

export default function CartPage() {
  return (
    // <HomeLayout>
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h3" gutterBottom>
        Shopping Cart
      </Typography>
      <Typography variant="h6" gutterBottom>
        You have 1 item in your cart
      </Typography>
      <Box sx={{ my: 7 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card
              elevation={0}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: 7,
                gap: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200 }}
                image="https://via.placeholder.com/150"
                alt="Voyager Paris Theo Laptop Bag-Blue"
                style={{ borderRadius: "30px" }}
              />
              <Stack spacing={1}>
                <Box>
                  <Typography variant="h4">
                    Voyager Paris Theo Laptop Bag-Blue
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    Free Shipping
                  </Typography>
                </Box>
                <Typography variant="h5" fontWeight={600}>
                  Price : 200
                </Typography>
                <Typography variant="h5" fontWeight={600}>
                  Quantity : 2
                </Typography>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              sx={{
                p: 2,
                borderRadius: 7,
                py: 4,
                border: "2px solid #204594",
              }}
            >
              <Stack spacing={2}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    Order Summary
                  </Typography>
                  <Divider />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">Net Order Value</Typography>
                  <Typography variant="p">2,100.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">
                    Total Order Value (Inc. all Taxes)
                  </Typography>
                  <Typography variant="p">2,100.00</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">Balance to pay in INR</Typography>
                  <Typography variant="p">2,100.00</Typography>
                </Box>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 4, textTransform: "none", borderRadius: 4 }}
              >
                Proceed to Checkout
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
    // </HomeLayout>
  );
}
