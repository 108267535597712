import React from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { secondaryColor } from '../../style/color';

export const MasterCard = ({
  src,
  alt,
  name,
  bgColor,
  users,
  vouchers,
  totalUsers,
  weekUsers,
  monthUsers,
  yearlyUsers,
  totalAmount,
  count,
}) => {
  return (
    <Card
      sx={{
        borderRadius: '1.25rem',
        height: '7.5rem',
        minWId: '15.9rem',
        width: { xl: '22.9375rem', md: '20rem', sm: '25rem', xs: '25rem' },
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <CardContent className='textArea'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <IconButton
              sx={{
                width: '4rem',
                height: '4rem',
                bgcolor: bgColor,

                mr: 3,
              }}
            >
              <img src={src} alt={alt}></img>
            </IconButton>
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Typography
              variant='p'
              color={secondaryColor.light}
              fontSize={'400'}
            >
              {name}
            </Typography>
            <Typography variant='h4'>
              {' '}
              {name == 'users' ? (
                users?.length
              ) : name == 'Total Users' ? (
                totalUsers
              ) : name == 'Users This Week' ? (
                weekUsers
              ) : name == 'Users This Month' ? (
                monthUsers
              ) : name == 'New Users' ? (
                yearlyUsers
              ) : name == 'Vouchers' ? (
                vouchers
              ) : name == 'Total Amount' ? (
                <>&#8377; {totalAmount}</>
              ) : (
                count
              )}
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
