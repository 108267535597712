export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'LOG_IN':
      // Handle the login action
      return { ...state, ...action.payload };
    case 'LOG_OUT':
      // Handle the logout action
      return {};
    default:
      return state;
  }
};
