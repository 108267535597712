import React, { useState } from "react";
import {
  IconButton,
  Popover,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Slider,
  TextField,
} from "@mui/material";
import { FilterAlt, FilterList } from "@mui/icons-material";

export default function Filterpop() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    categories: {
      audio: false,
      beauty: false,
      electronics: false,
      food: false,
      gifts: false,
    },
    amountRange: [122, 267600],
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (event) => {
    setFilters({
      ...filters,
      categories: {
        ...filters.categories,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleSliderChange = (event, newValue) => {
    setFilters({
      ...filters,
      amountRange: newValue,
    });
  };
  const handleClearAll = () => {
    setFilters({
      categories: {
        audio: false,
        beauty: false,
        electronics: false,
        food: false,
        gifts: false,
      },
      amountRange: [0, 300000],
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <FilterAlt sx={{ fontSize: "30px", color: "#204594" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={2} width={300}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Filters</Typography>
            <Typography variant="h6" onClick={handleClearAll} color="primary">
              Clear All
            </Typography>
          </Box>{" "}
          <Box my={2}>
            <Typography variant="subtitle1">Categories</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.categories.audio}
                  onChange={handleCategoryChange}
                  name="audio"
                />
              }
              label="Audio Accessories"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.categories.beauty}
                  onChange={handleCategoryChange}
                  name="beauty"
                />
              }
              label="Beauty And Personal Care"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.categories.electronics}
                  onChange={handleCategoryChange}
                  name="electronics"
                />
              }
              label="Electronics And Accessories"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.categories.food}
                  onChange={handleCategoryChange}
                  name="food"
                />
              }
              label="Food And Beverage"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.categories.gifts}
                  onChange={handleCategoryChange}
                  name="gifts"
                />
              }
              label="Gift Hampers"
            />
          </Box>
          <Box my={2}>
            <Typography variant="subtitle1">Amount Range</Typography>
            <Slider
              value={filters.amountRange}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              min={0}
              max={300000}
            />
            <Box display="flex" justifyContent="space-between" mt={1}>
              <TextField
                label="Min"
                variant="outlined"
                size="small"
                value={filters.amountRange[0]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ mx: 1 }}
              />
              <TextField
                label="Max"
                variant="outlined"
                size="small"
                value={filters.amountRange[1]}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ mx: 1 }}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
