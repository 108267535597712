import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  Snackbar,
  IconButton,
} from "@mui/material";
import InputField1 from "../../components/mui/InputField1";
import CustomButton from "../../components/mui/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { updateCompanyDetails, uploadDocument } from "../../actions/userAuth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const EditCompanyInfo = () => {
  const companyDetails = JSON.parse(localStorage.getItem("siriPayUser"));
  console.log(companyDetails);

  const [company, setCompany] = useState(companyDetails?.companyName);
  const [companyType, setCompanyType] = useState(companyDetails?.companyType);
  const [CINNumber, setCINNumber] = useState(companyDetails?.cinNumber);
  const [aadhar, setAadhar] = useState(companyDetails?.directorAdhaarNumber);
  const [directorPanCardNumber, setDirectorPanCardNumber] = useState(
    companyDetails?.directorPanCardNumber
  );
  const [companyPAN, setCompanyPAN] = useState(
    companyDetails?.companyPanCardNumber
  );
  const [GSTStatus, setGSTStatus] = useState(companyDetails?.gstStatus);
  const [tradeName, setTradeName] = useState(companyDetails?.tradeName);
  const [officeAddress, setOfficeAddress] = useState(
    companyDetails?.officeAddress
  );
  const [addressProofType, setAddressProofType] = useState(
    companyDetails?.addressProofType
  );
  const [gstDocument, setGstDocument] = useState(
    companyDetails?.gstDocumentUrl
  );
  const [aadharDocument, setAadharDocument] = useState(null);
  const [panCardDocument, setPanCardDocument] = useState(null);
  const [addressProofDocument, setAddressProofDocument] = useState(null);
  const [MSMEDocument, setMSMEDocument] = useState(null);
  const [gstDocumentUrl, setGstDocumentUrl] = useState(
    companyDetails?.gstDocument
  );
  const [aadharDocumentUrl, setAadharDocumentUrl] = useState(
    companyDetails?.adhaarDocument
  );
  const [panCardDocumentUrl, setPanCardDocumentUrl] = useState(
    companyDetails?.panCardDocument
  );
  const [addressProofDocumentUrl, setAddressProofDocumentUrl] = useState(
    companyDetails?.addressProofDocument
  );
  const [MSMEDocumentUrl, setMSMEDocumentUrl] = useState(
    companyDetails?.addressProofDocument
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);
  const handleFileUpload = (file, setUrlState) => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      dispatch(
        uploadDocument(
          formData,
          (response) => {
            console.log("File uploaded successfully:", response.data);
            setUrlState(response.data);
            enqueueSnackbar("File uploaded successfully.", {
              variant: "success",
            });
            setSnackbarMessage("File uploaded successfully.");
            setSnackbarOpen(true);
          },
          (error) => {
            console.error("Error uploading file:", error.message);

            enqueueSnackbar(error.message, {
              variant: "error",
            });

            setSnackbarOpen(true);
          }
        )
      );
    }
  };

  // const handleChangeFile = (e, setFileState, setUrlState) => {
  //   const file = e.target.files[0];
  //   setFileState(file);
  //   handleFileUpload(file, setUrlState);
  // };

  const handleChangeFile = (e, setFileState, setUrlState) => {
    const file = e.target.files[0];

    if (file && file.type !== "application/pdf") {
      alert("Please upload a PDF document.");
      e.target.value = ""; // Clear the file input
      return;
    }

    setFileState(file);
    handleFileUpload(file, setUrlState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedCompanyDetails = {
      _id: companyDetails._id,
      companyName: company,
      companyType,
      cinNumber: CINNumber,
      directorAdhaarNumber: aadhar,
      directorPanCardNumber,
      companyPanCardNumber: companyPAN,
      gstStatus: GSTStatus,
      tradeName,
      officeAddress,
      addressProofType,
      gstDocument: gstDocumentUrl,
      adhaarDocument: aadharDocumentUrl,
      panCardDocument: panCardDocumentUrl,
      addressProofDocument: addressProofDocumentUrl,
      msmeProofDocumentUrl: MSMEDocumentUrl,
    };

    try {
      await dispatch(updateCompanyDetails(updatedCompanyDetails));
      setSnackbarMessage("Company details updated successfully");
      enqueueSnackbar("Company details updated successfully", {
        variant: "success",
      });
      setSnackbarOpen(true);
      navigate(`/customer/company/add-gst`);
    } catch (error) {
      console.error("Error updating company details:", error);

      enqueueSnackbar(error.message, {
        variant: "error",
      });
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Paper>
      <Box>
        <Typography variant="h4" py={4} px={10}>
          Edit Company Details
        </Typography>
        <Divider />
      </Box>
      <Box px={{ md: 10, xs: 2 }} py={5}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Company Name"
                placeholder="Enter Company name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Company Type"
                placeholder="Enter Company Type"
                value={companyType}
                onChange={(e) => setCompanyType(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="CIN Number"
                placeholder="Enter CIN Number"
                value={CINNumber}
                onChange={(e) => setCINNumber(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label={`Director's Aadhar Number`}
                placeholder={`Enter Director's Aadhar Number`}
                value={aadhar}
                onChange={(e) => setAadhar(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label={`Director's PAN card Number`}
                placeholder={`Enter Director's PAN Card Number`}
                value={directorPanCardNumber}
                onChange={(e) => setDirectorPanCardNumber(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Company PAN Card Number"
                placeholder="Enter Company PAN Card Number"
                value={companyPAN}
                onChange={(e) => setCompanyPAN(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="GST Status"
                placeholder="Enter GST Status"
                value={GSTStatus}
                onChange={(e) => setGSTStatus(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Trade Name"
                placeholder="Enter Trade Name"
                value={tradeName}
                onChange={(e) => setTradeName(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Office Address"
                placeholder="Enter Office Address"
                value={officeAddress}
                onChange={(e) => setOfficeAddress(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Address Proof Type"
                placeholder="Enter Address Proof Type"
                value={addressProofType}
                onChange={(e) => setAddressProofType(e.target.value)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Upload GST Document"
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  handleChangeFile(e, setGstDocument, setGstDocumentUrl)
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Upload Aadhar Document"
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  handleChangeFile(e, setAadharDocument, setAadharDocumentUrl)
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Upload PAN Card Document"
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  handleChangeFile(e, setPanCardDocument, setPanCardDocumentUrl)
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Upload Address Proof Document"
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  handleChangeFile(
                    e,
                    setAddressProofDocument,
                    setAddressProofDocumentUrl
                  )
                }
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputField1
                label="Upload MSME Proof Document"
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  handleChangeFile(e, setMSMEDocument, setMSMEDocumentUrl)
                }
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <CustomButton
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
            >
              Update
            </CustomButton>
          </Box>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Box>
    </Paper>
  );
};

export default EditCompanyInfo;
