import axios from "axios";
import { deleteAPI, getAPI, patchAPI, postAPI, putAPI } from "../utils/api";
import endpoints from "../utils/endpoints";

export const login = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.SIGNIN,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const signUp = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.SIGNUP,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const verifyOtp = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.VERIFY_OTP,
      { otp: payload.otp },
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      payload.token
    );
  };
};

export const getAllUsers = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_ALL_EMPLOYEES,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const getAllEmployee = (callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoints?.GET_USER_EMPLOYEE,
      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const updateEmployeeStatusById = (
  id,
  payload,
  callBack,
  errorCallback
) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.UPDATE_STATUS_EMPLOYEE + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("token")
    );
  };
};
export const updateUserStatusById = (id, payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.GET_ALL_USERS + "/" + id,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("token")
    );
  };
};

export const userLogout = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.LOGOUT,
      {},
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      payload.token
    );
  };
};
export const getUserByToken = (callBack, errorCallback, manualToken) => {
  console.log(manualToken);
  return (dispatch) => {
    getAPI(
      endpoints?.GET_USER_BY_TOKEN,
      {},
      (response) => {
        console.log("Response For Getting User Information", response);
        callBack(response);
      },
      (error) => {
        console.log("Error While Getting User Information", error);
        errorCallback(error);
      },
      manualToken
    );
  };
};

export const sendOtp = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.GET_OTP,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const addEmployeesByExcelSheet = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.ADD_EMPLOYEES_BY_EXCELSHEET,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const verifyGstNumber = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.VERIFY_GST,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const addEmployee = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.ADD_EMPLOYEES,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      payload.token
    );
  };
};

export const uploadDocument = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.UPLOAD_DOC,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateCompanyDetails = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.UPDATE_COMPANY_DETAILS,
      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};

export const addEmployeesByExcel = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchAPI(
      endpoints?.ADD_EMPLOYEES_BY_EXCELSHEET,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      },
      localStorage.getItem("token")
    );
  };
};

export const addRecharge = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints.RECHARGE_WALLET,

      payload,
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const employeeRewardsPoint = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.EMPLOYEES_POINT,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const rewardsPointSheet = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoints?.REWARDS_POINTSHEET,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const RechargeRequestById = (CompanyId, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      `${endpoints.GET_RECHARGE_REQUEST}${CompanyId}`,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};

//   payload,
//   callBack,
//   errorCallback
// ) => {
//   return (dispatch) => {
//     postAPI(
//       endpoints?.EMPLOYEES_POINT,
//       payload,
//       (response) => {
//         callBack(response);
//       },
//       (error) => {
//         errorCallback(error);
//       }
//     );
//   };
// };
// export const rewardsPointSheet = (
//   payload,
//   callBack,
//   errorCallback
// ) => {
//   return (dispatch) => {
//     postAPI(
//       endpoints?.REWARDS_POINTSHEET,
//       payload,
//       (response) => {
//         callBack(response);
//       },
//       (error) => {
//         errorCallback(error);
//       }
//     );
//   };
// };
export const walletDataById = (CompanyId, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      `${endpoints.GET_COMPANY_WALLET_DATA}${CompanyId}`,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
export const companyById = (CompanyId, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      `${endpoints.GET_COMPANY_BY_ID}${CompanyId}`,

      {},
      (response) => {
        callBack(response);
      },
      (err) => {
        errorCallback(err);
      },
      localStorage.getItem("token")
    );
  };
};
