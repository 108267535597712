const version = "v1";
const service = "staging";

export default {
  LOGOUT: `${version}/${service}/business/logout`,
  SIGNUP: `${version}/${service}/business/signup`,
  GET_OTP: `${version}/${service}/business/getotp`,
  SIGNIN: `${version}/${service}/business/login`,
  VERIFY_OTP: `${version}/${service}/business/verifyOtp`,
  VERIFY_GST: `${version}/${service}/gst/verifyGstNumber`,
  GET_ALL_EMPLOYEES: `${version}/${service}/employee/getAllUsers`,
  ADD_EMPLOYEES: `${version}/${service}/employee/addEmployee`,
  UPLOAD_DOC: `${version}/${service}/companyDetails/uploadDocument`,
  UPDATE_COMPANY_DETAILS: `${version}/${service}/companyDetails/updateCompanyDetails`,
  ADD_EMPLOYEES_BY_EXCELSHEET: `${version}/${service}/employee/addEmployeesByExcelSheet`,
  EMPLOYEES_POINT: `${version}/${service}/business/rewardPointTransactionBusiness`,
  REWARDS_POINTSHEET: `${version}/${service}/business/rewardPointTransactionBusinessBulk`,
  RECHARGE_WALLET: `${version}/${service}/recharge/rechargeRequests`,
  GET_RECHARGE_REQUEST: `${version}/${service}/recharge/getRechargeRequestsByBusinessAccountId/`,
  EMPLOYEES_POINT: `${version}/${service}/business/rewardPointTransactionBusiness`,
  REWARDS_POINTSHEET: `${version}/${service}/business/rewardPointTransactionBusinessBulk`,
  RECHARGE_WALLET: `${version}/${service}/recharge/rechargeRequests`,
  GET_COMPANY_WALLET_DATA: `${version}/${service}/transactions/getTransactionBusinessById/`,
  GET_COMPANY_BY_ID: `${version}/${service}/business/getBusinessAccountUserById/`,
  WALLET_TRANSACTIONS: `${version}/${service}/recharge/getRechargeRequestsByBusinessAccountId`,
  GET_USER_EMPLOYEE: `${version}/${service}/employee/getAllEmployees`,
  UPDATE_STATUS_EMPLOYEE: `${version}/${service}/employee/changeStatus`,
};
