import React, { useEffect, useState } from "react";
import HomeLayout from "../../asset/layout/HomeLayout";
import { ProfileCard } from "../../components/Cards/ProfileCard";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { getAllEmployee } from "../../actions/userAuth";

export const DashBoard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noUsersMessage, setNoUsersMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  const dispatch = useDispatch();

  const getData = () => {
    setLoading(true);
    dispatch(
      getAllEmployee(
        (data) => {
          console.log("Data fetched successfully:", data.data);
          setLoading(false);
          if (Array.isArray(data.data)) {
            setUsers(data.data);
            setNoUsersMessage("");
          } else {
            setUsers([]);
            setNoUsersMessage(data.data.message || "No data available");
          }
        },
        (error) => {
          console.error("Error fetching data:", error);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    getData();
  }, [dispatch]);

  console.log("length ", users);

  return (
    <Box sx={{ display: "flex", background: "#F5F7FA" }}>
      <HomeLayout>
        <Container maxWidth={"xl"}>
          <Typography mt={5} mb={2} variant="h3">
            Recent Users
          </Typography>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : users.length === 0 ? (
            <Typography variant="h6" align="center">
              {noUsersMessage}
            </Typography>
          ) : (
            <Grid container spacing={3}>
              {users?.length > 0 &&
                users.map((data, index) => (
                  <Grid item key={index} md={3} xs={12}>
                    <ProfileCard
                      {...data}
                      showMessageButton={false}
                      image={data}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </Container>
      </HomeLayout>
    </Box>
  );
};
