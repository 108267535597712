import React from "react";
import HomeLayout from "../../asset/layout/HomeLayout";
import { Box, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Products from "./Products";
import CartPage from "./cart/CartPage";

export default function Merchandise() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <HomeLayout>
      <Box>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} sx={{ my: 6 }}>
            <Tab label="Products" value="1" />
            <Tab
              label="My Orders
"
              value="2"
            />
            <Tab label="Help Desk" value="3" />
            <Tab label="Cart Page" value="4" />
          </Tabs>
          <TabPanel value="1">
            <Products />{" "}
          </TabPanel>
          <TabPanel value="2">hrllo </TabPanel>
          <TabPanel value="3">hrllo </TabPanel>
          <TabPanel value="4">
            {" "}
            <CartPage />{" "}
          </TabPanel>
        </TabContext>
      </Box>
    </HomeLayout>
  );
}
