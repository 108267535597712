import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Container,
} from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/mui/CustomButton';
import pdfjsLib from 'pdfjs-dist';

const CompanyInfo = () => {
  const user = JSON.parse(localStorage.getItem('siriPayUser'));

  const companyDetails = [
    { label: 'Company Name', value: user?.companyName },
    { label: 'GST Number', value: user?.gst },
    { label: 'Company Type', value: user?.companyType },
    { label: 'CIN Number', value: user?.cinNumber },
    {
      label: 'Company Pan Card Number',
      value: user?.companyPanCardNumber,
    },
    { label: 'Trade Name', value: user?.tradeName },
    {
      label: 'Director Pan Card Number',
      value: user?.directorPanCardNumber,
    },
    {
      label: 'Director Aadhaar Number',
      value: user?.directorAdhaarNumber,
    },
    { label: 'Office Address', value: user?.officeAddress },
    { label: 'GST Document', value: user?.gstDocument },
    { label: 'Pan Card Document', value: user?.panCardDocument },
    {
      label: 'Address Proof Document',
      value: user?.addressProofDocument,
    },
    { label: 'Aadhaar Document', value: user?.adhaarDocument },
  ];

  const navigate = useNavigate();

  const handleEdit = () => {
    navigate('/edit-company-info', {
      state: { user },
    });
  };

  const pdfWorkerUrl = pdfjsLib
    ? `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`
    : '';

  return (
    <Container>
      <Box mt={5}>
        <Box md={10} textAlign={'end'}>
          <Grid
            container
            justifyContent={'space-between'}
            mb={5}
          >
            <Grid item>
              {' '}
              <Typography
                textAlign={'center'}
                variant='h3'
                sx={{ fontSize: '2rem' }}
              >
                Company Details
              </Typography>{' '}
            </Grid>
            <Grid item>
              {' '}
              <CustomButton
                variant='contained'
                onClick={handleEdit}
              >
                Edit company information
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} md={10}>
              <TableContainer component={Paper} elevation={3}>
                <Table>
                  <TableBody>
                    {companyDetails?.map((detail, index) => (
                      <TableRow key={index}>
                        <TableCell xs={{ width: '40%' }}>
                          <Typography
                            variant='h5'
                            fontWeight={'bold'}
                            align='start'
                            ml={2}
                          >
                            {detail?.label}
                          </Typography>
                        </TableCell>
                        <TableCell xs={{ width: '60%' }}>
                          <Typography align='start'>
                            {detail?.value}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default CompanyInfo;
