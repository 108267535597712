import { FilterAlt } from "@mui/icons-material";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Filterpop from "../../components/mui/Filterpop";
import { Menu, MenuItem, Button } from "@mui/material";

export default function Products() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState("Recommended");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, value) => {
    setAnchorEl(null);
    if (value) {
      setSelectedItem(value);
    }
  };
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Button
              aria-controls="sort-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{
                // border: "1px solid #000",
                borderRadius: "4px",
                padding: "4px 12px",
                backgroundColor: "#fff",
                color: "#000",
                // fontWeight: "bold",
                textTransform: "none",
              }}
            >
              Sort: {selectedItem}
            </Button>
            <Menu
              id="sort-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "sort-button",
                style: {
                  padding: "0",
                },
              }}
              PaperProps={{
                style: {
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  marginTop: "4px",
                },
              }}
            >
              <MenuItem onClick={(event) => handleClose(event, "Recommended")}>
                Recommended
              </MenuItem>
              <MenuItem
                onClick={(event) => handleClose(event, "Recently Added")}
              >
                Recently Added
              </MenuItem>
              <MenuItem
                onClick={(event) => handleClose(event, "Price: Low to High")}
              >
                Price: Low to High
              </MenuItem>
              <MenuItem
                onClick={(event) => handleClose(event, "Price: High to Low")}
              >
                Price: High to Low
              </MenuItem>
            </Menu>
          </Box>
          <Filterpop />
        </Box>
        <Box sx={{ height: 250, borderRadius: 20, my: 4 }}>
          <img
            src="/logo.webp"
            alt="img"
            style={{
              height: "100%",
              width: "100%",
              objectFit: "fill",
              borderRadius: "40px",
            }}
          />
        </Box>
        <Box my={4}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Card
                elevation={0}
                sx={{ p: 1.5, borderRadius: 4, boxShadow: 2 }}
              >
                <Stack spacing={2}>
                  <Box
                    sx={{
                      height: "200px",
                      borderRadius: 4,
                      //   bgcolor: "blue",
                    }}
                  ></Box>
                  <Box textAlign={"center"}>
                    <Typography variant="h6">American Tourister</Typography>
                    <Typography variant="h4" mt={1}>
                      INR 450
                    </Typography>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
