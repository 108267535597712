import React, { useState } from "react";
import Section from "../../components/Section";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import SectionImg from "../../media/image/section2img.png";
import LogoImg from "../../media/image/logo.png";
import { Link, useNavigate } from "react-router-dom";
import InputField1 from "../../components/mui/InputField1";
import CustomButton from "../../components/mui/CustomButton";
import { useDispatch } from "react-redux";
import { login, verifyOtp } from "../../actions/userAuth";
import { enqueueSnackbar } from "notistack";
import OTPInput from "react-otp-input";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpInputActive, setOtpInputActive] = useState(false);
  const [otp, setOtp] = useState("");
  const [gettoken, setGetToken] = useState("");

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setIsEmailValid(validateEmail(enteredEmail));
  };

  const handleContinue = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    if (isEmailValid) {
      dispatch(
        login(
          { email: email },
          (data) => {
            enqueueSnackbar("Otp sent successfully", {
              variant: "success",
            });
            // localStorage.setItem('token', data.data.token);
            setGetToken(data.data.token);
            console.log("token jwt", data.data.token);
            setOtpInputActive(true);
            setLoginLoading(false);
          },
          (err) => {
            const errorMessage =
              err.response?.data?.message ||
              "An error occurred. Please try again.";
            enqueueSnackbar(errorMessage, {
              variant: "error",
            });
            setLoginLoading(false);
          }
        )
      );
    }
  };
  // console.log("token from set", gettoken);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    console.log("token", gettoken);

    if (isEmailValid) {
      dispatch(
        verifyOtp(
          {
            otp: otp,
            token: gettoken,
          },
          (data) => {
            console.log("data after verify otp", data);
            localStorage.setItem("token", gettoken);
            localStorage.setItem("siriPayUser", JSON.stringify(data.data.data));
            localStorage.setItem("siriPayUserID", data.data.data._id);
            dispatch({
              type: "LOG_IN",
              payload: data.data.data,
            });
            enqueueSnackbar("Logged in successfully", {
              variant: "success",
            });
            setLoginLoading(false);
            navigate("/dashboard");
          },
          (err) => {
            enqueueSnackbar(err.response.data.message, {
              variant: "error",
            });
            setLoginLoading(false);
            console.log(err.response.data.message);
          }
        )
      );
    }
  };

  const otpStyle = {
    input: {
      minHeight: 40,
      fontSize: 16,
      flexGrow: 1,
      "&+input": { ml: 2 },

      appearance: "textfield",
    },
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
      display: "none",
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
      display: "none",
    },
  };
  const inputStyle = {
    ...otpStyle.input,
    width: "15%",
    height: "4rem",
    margin: "1%",
    borderRadius: "8px",
    border: "1px solid gray",
    background: "#fff",
    textAlign: "center",
    "&:hover, &:active, &:focus": {
      "& .otp-input-arrow": {
        display: "none",
        border: "1px solid gray",
      },
    },
  };

  return (
    <Section>
      <Container maxWidth={"xl"}>
        <Box mt={4}>
          <img
            src="/logo.webp"
            style={{
              height: {
                xl: "41.01px",
                md: "41.01px",
                xs: "20px",
              },
              width: "199px",
            }}
            alt="logo"
          />
        </Box>
        <Grid
          container
          spacing={1}
          alignItems={{
            xl: "center",
            xs: "flex-start",
            md: "center",
            sm: "flex-start",
          }}
          sx={{
            minHeight: "calc(100vh - 100px)",
            marginTop: {
              xl: "-5%",
              xs: "-10%",
              md: "-10%",
              sm: "-5%",
            },
          }}
        >
          <Grid item md={5} xs={12}>
            <Box
              my={7}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                mx: {
                  xs: 0,
                  sm: 0,
                  md: 6,
                  lg: 6,
                  xl: 6,
                },
              }}
            >
              <Box sx={{ pb: 4 }}>
                <Typography variant="h1" component={"h2"}>
                  Login
                </Typography>
                <Typography
                  variant="h5"
                  component={"p"}
                  mt={1}
                  sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                >
                  Please enter your email to access your account.
                </Typography>
              </Box>
              <Box maxWidth={600} mx={"auto"}>
                <form>
                  <Stack sx={{ marginBottom: "2%" }}>
                    <InputField1
                      label={"Enter Email"}
                      placeholder={"Your Email"}
                      value={email}
                      required
                      maxLength={50}
                      disabled={otpInputActive}
                      onChange={handleEmailChange}
                    />
                  </Stack>
                  {!isEmailValid ? (
                    <Stack>
                      <Typography sx={{ color: "red" }}>
                        Please enter a valid email number
                      </Typography>
                    </Stack>
                  ) : (
                    ""
                  )}
                  {!otpInputActive ? (
                    <>
                      <Box sx={{ opacity: ".1" }} mt={3}>
                        <Stack>
                          <Typography variant="p">Enter OTP</Typography>
                        </Stack>
                        <Stack>
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            inputType="number"
                            renderSeparator={<span> </span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={inputStyle}
                                disabled={!otpInputActive}
                              />
                            )}
                            isInputNumValid={() => false}
                          />
                        </Stack>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Stack mt={3}>
                        <Typography variant="p">Enter OTP</Typography>
                      </Stack>
                      <Stack>
                        <Box>
                          <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            inputType="number"
                            renderSeparator={<span> </span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={inputStyle}
                                disabled={!otpInputActive}
                              />
                            )}
                            isInputNumValid={() => false}
                          />
                        </Box>
                      </Stack>
                    </>
                  )}
                  {!otpInputActive ? (
                    <Stack mt={4}>
                      <CustomButton
                        onClick={handleContinue}
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={
                          !validateEmail(email) ||
                          email.length === 0 ||
                          loginLoading
                        }
                      >
                        {loginLoading ? <CircularProgress /> : "Continue"}
                      </CustomButton>
                    </Stack>
                  ) : (
                    <Stack mt={4}>
                      <CustomButton
                        onClick={handleSubmit}
                        variant="contained"
                        fullWidth
                        color="primary"
                        disabled={
                          (otpInputActive && otp.length !== 5) || loginLoading
                        }
                      >
                        {loginLoading ? <CircularProgress /> : "Submit"}
                      </CustomButton>
                    </Stack>
                  )}
                </form>
              </Box>
              <Box mt={2}>
                <Box mt={3}>
                  <Typography textAlign={"center"}>
                    Don't have a sign up? <Link to="/signup">Sign up</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={7} sx={{ display: { md: "block", xs: "none" } }}>
            <Box
              sx={{
                my: 2,
                px: 5,
                py: 4,
                minHeight: "calc(100vh - 200px)",
                display: "flex",
                alignItems: "center",
                img: { width: "100%", height: "auto" },
                borderRadius: 3,
              }}
            >
              <Box>
                <img
                  src={SectionImg}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "3px",
                  }}
                  alt="Products"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
