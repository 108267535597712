// import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";

import { Avatar, Fade, Menu, MenuItem } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useLocation, useNavigate } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useDispatch } from "react-redux";
import { userLogout } from "../../actions/userAuth";
import WalletModal from "../../components/mui/WalletModal";
import { enqueueSnackbar } from "notistack";

const drawerWidth = 240;

const drawerItems = [
  {
    icon: <img src="/home2.svg" alt="Dashboard" />,
    icon2: <img src="/home.svg" alt="Dashboard" />,
    text: "Dashboard",
    LinkUrl: "/dashboard",
  },
  {
    icon: <img src="/company2.svg" alt="Home" />,
    icon2: <img src="/company.svg" alt="home" />,
    text: "Company",
    LinkUrl: "/customer/company/add-gst",
  },
  {
    icon: <img src="/user2.svg" alt="employee" />,
    icon2: <img src="/user.svg" alt="employee" />,
    text: "Employee",
    LinkUrl: "/company/employee",
  },
  {
    icon: <img src="/Transations2.svg" alt="Transactions" />,
    icon2: <img src="/Transactions.svg" alt="Transactions" />,
    text: "Recharge Wallet",
    LinkUrl: "/recharge-wallet",
  },
  {
    icon: <img src="/point2.svg" alt="Transactions" />,
    icon2: <img src="/point.svg" alt="Transactions" />,
    text: "Rewards Point",
    LinkUrl: "/rewards/rewards-point",
  },
  // {
  //   icon: <img src="/merchandise2.svg" alt="Transactions" />,
  //   icon2: <img src="/merchandise.svg" alt="Transactions" />,
  //   text: "Merchandise",
  //   LinkUrl: "/Merchandise/Merchandise",
  // },
];

function HomeLayout(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [walletModalOpen, setWalletModalOpen] = useState(false);

  const Navigator = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     navigate("/");
  //   }
  // }, []);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleOpenWalletModal = () => {
    setWalletModalOpen(true);
  };

  const handleCloseWalletModal = () => {
    setWalletModalOpen(false);
  };

  const isActive = (linkUrl) => location.pathname === linkUrl;

  const drawer = (
    <div>
      <Box
        my={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/logo.webp"
          alt="logo"
          style={{ width: "auto", height: "3.5rem" }}
        />
      </Box>

      <List>
        {drawerItems?.map((item, index) => (
          <ListItem disablePadding key={index}>
            {" "}
            <ListItemButton
              onClick={() => {
                Navigator(item.LinkUrl);
              }}
            >
              <div
                style={{
                  height: "38px",
                  borderRadius: "0 10px 10px 0",
                  border: "3px solid ",
                  marginLeft: "-8%",
                  color: isActive(item.LinkUrl) ? "#11409B" : "white",
                  "&:hover": {
                    color: "#11409B",
                  },
                }}
              ></div>

              <ListItemIcon
                style={{
                  marginLeft: "5%",
                  color: isActive(item.LinkUrl) ? "#11409B" : "gray",
                  "&:hover": {
                    color: "#11409B",
                  },
                }}
              >
                {isActive(item.LinkUrl) ? item.icon : item.icon2}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{
                  color: isActive(item.LinkUrl) ? "#11409B" : "gray",
                  "&:hover": {
                    color: "#11409B",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(
      userLogout(
        {},
        (data) => {
          localStorage.clear();

          navigate("/");
        },
        (error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
          localStorage.clear();
          navigate("/");
        }
      )
    );
  };
  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: "flex",

        minHeight: "100vh",
        height: "auto",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "white",
        }}
      >
        <Toolbar color="white">
          <IconButton
            color="pink"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                style={{
                  color: '#8BA3CB',
                  paddingTop: '3px',
                  height: {
                    xs: '0rem',
                    sm: '0rem ',
                    md: '0rem',
                    lg: 'none',
                    xl: 'none',
                  },
                }}
                placeholder='Search for something'
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search> */}

            <Box>
              <IconButton
                size="large"
                aria-label="show 1 new notifications"
                color="primary.main"
                onClick={handleOpenWalletModal}
                sx={{
                  backgroundColor: "primary",
                  "&:hover": {
                    backgroundColor: "",
                  },
                  color: "primary.main",

                  marginRight: "1rem",
                }}
              >
                <AccountBalanceWalletIcon sx={{ transform: "scale(1.5)" }} />
              </IconButton>
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onMouseEnter={handleMouseEnter}
              >
                <Avatar />
              </IconButton>

              {/* Menu triggered by hover */}
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMouseLeave} // Close when clicking outside or moving mouse away
                TransitionComponent={Fade}
                MenuListProps={{
                  onMouseLeave: handleMouseLeave, // Close the menu when mouse leaves
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>

              <WalletModal
                open={walletModalOpen}
                onClose={handleCloseWalletModal}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          background: "#F5F7FA",

          overflowY: "auto", // Allow vertical scrolling when needed
          "&::-webkit-scrollbar": {
            width: "0", // Hide scrollbar for WebKit browsers
          },
          scrollbarWidth: "none",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

HomeLayout.propTypes = {
  window: PropTypes.func,
  children: PropTypes.node,
};

export default HomeLayout;
